import type { useFileStorage } from "@msuite/picasso";
import { Box, Text, VStack } from "@msuite/picasso";
import type { FC } from "react";
import { TbPdf } from "react-icons/tb";

/** Props Interface */
interface DocumentFileProps {
	src: string | undefined;
	metadata: ReturnType<typeof useFileStorage>["metadata"];
}

export const DocumentFile: FC<DocumentFileProps> = ({ metadata }) => {
	/** Render */
	return (
		<VStack
			w="80%"
			maxW="80%"
			alignItems="center"
			spacing={0}
		>
			<Box
				fontSize="5xl"
				color="gray.500"
			>
				<TbPdf />
			</Box>
			<Text
				fontSize="sm"
				wordBreak="break-word"
				noOfLines={2}
				textAlign="center"
				pb="3px"
			>
				{metadata?.name}
			</Text>
		</VStack>
	);
};
