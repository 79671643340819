import { db } from "@/core";
import type { Employee, IWorkspace } from "@msuite/katana";
import { moment } from "@msuite/katana";
import { DocumentString, FormControl, FormLabel, Grid, GridItem, Text } from "@msuite/picasso";
import { type FC, Fragment } from "react";
import { useCallNoteOverviewContext } from "./context";

export const OverviewCompanyInformation: FC = () => {
	/** Context */
	const { callNote } = useCallNoteOverviewContext();

	/** Render */
	return (
		<Grid
			templateColumns="repeat(2, 1fr)"
			gap={6}
		>
			<GridItem colSpan={2}>
				<FormControl>
					<FormLabel>Angerufenes Unternehmen</FormLabel>
					<Text>
						<DocumentString<IWorkspace>
							db={db}
							path={`plangruppen/${callNote.workspaceId}`}
							fields={["name"]}
						/>
					</Text>
				</FormControl>
			</GridItem>

			<FormControl>
				<FormLabel>Anruf aufgenommen am</FormLabel>
				<Text>{moment(callNote.receivedAt).longWithTime()}</Text>
			</FormControl>

			<FormControl>
				<FormLabel>Anruf aufgenommen von</FormLabel>
				<Text>
					<DocumentString<Employee>
						db={db}
						path={`mitarbeiter/${callNote.receivedBy}`}
						fields={["vorname", "nachname"]}
					/>
				</Text>
			</FormControl>
			{callNote.answeredAt && (
				<Fragment>
					<FormControl>
						<FormLabel>Beantwortet am</FormLabel>
						<Text>{moment(callNote.answeredAt).longWithTime()}</Text>
					</FormControl>

					<FormControl>
						<FormLabel>Beantwortet von</FormLabel>
						<Text>
							<DocumentString<Employee>
								db={db}
								path={`mitarbeiter/${callNote.answeredBy}`}
								fields={["vorname", "nachname"]}
							/>
						</Text>
					</FormControl>
				</Fragment>
			)}
		</Grid>
	);
};
