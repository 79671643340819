import type { Absence } from "@msuite/katana";
import { createContext, useContext } from "react";

/** Interface */
interface ICallNoteFormContext {
	workspaceIdSelected: boolean;
	isInAbsence: boolean;
	absence: Absence | null;
}

/** Context */
export const CallNoteFormContext = createContext<ICallNoteFormContext>({} as ICallNoteFormContext);

/** Hooks */
export const useCallNoteFormContext = () => {
	return useContext(CallNoteFormContext);
};
