import type { EntryComment } from "@msuite/katana";
import { FormErrorMessage } from "@msuite/picasso";
import * as yup from "yup";

export const FormError = FormErrorMessage<EntryComment>;

export const entryCommentSchema = yup.object().shape({
	comment: yup.string().when("commentFiles", {
		is: (commentFiles: string[]) => (commentFiles?.length ?? 0) === 0,
		then: yup.string().required("Bitte geben Sie einen Kommentar ein oder fügen Sie Bilder hinzu."),
		otherwise: yup.string().optional(),
	}),
});
