import { DASHBOARD_VACATIONS_LAYOUT_ID } from "@/sites/home/use-dashboard";
import {
	ContentContainer,
	Modal,
	ModalCloseButton,
	ModalContentContainer,
	ModalOverlay,
	useAuthContext,
} from "@msuite/picasso";
import type { FC } from "react";
import { Control } from "./control";
import { List } from "./list";

/** Props Interface */
interface AbsencesOverviewModalProps {
	isOpen: boolean;
	onClose: () => void;
	employeeId?: string;
}

export const AbsencesOverviewModal: FC<AbsencesOverviewModalProps> = ({ isOpen, onClose, employeeId: _employeeId }) => {
	/** Context */
	const { userId } = useAuthContext();
	const employeeId = _employeeId ?? userId;

	if (!employeeId) return null;

	/** Render */
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
		>
			<ModalOverlay />
			<ModalContentContainer
				width="100rem"
				layoutId={DASHBOARD_VACATIONS_LAYOUT_ID}
			>
				<ModalCloseButton />
				<ContentContainer>
					<List employeeId={employeeId} />
					<Control
						onClose={onClose}
						employeeId={employeeId}
					/>
				</ContentContainer>
			</ModalContentContainer>
		</Modal>
	);
};
