import { db, storage } from "@/core";
import type { Offer } from "@msuite/katana";
import { Button, DocumentString, FormControl, FormLabel, HStack, useUIContext } from "@msuite/picasso";
import { getDownloadURL, ref } from "firebase/storage";
import { useState, type FC } from "react";
import { useCallNoteOverviewContext } from "./context";

export const OverviewReasonOffer: FC = () => {
	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);

	/** Context */
	const { colors } = useUIContext();
	const { callNote } = useCallNoteOverviewContext();

	/** Fallback */
	if (callNote.reason.type !== "offer") return null;

	/** Functions */
	async function handleOnOpenOffer() {
		try {
			setIsLoading(true);
			if (callNote.reason.type !== "offer") return;
			const storageRef = ref(storage, `offers/${callNote.reason.offerId}.pdf`);
			const downloadUrl = await getDownloadURL(storageRef);
			window.open(downloadUrl, "_blank");
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}

	/** Render */
	return (
		<FormControl>
			<FormLabel>Angebot</FormLabel>
			<HStack
				justify="space-between"
				spacing={3}
				backgroundColor={colors.grayLight}
				py={2}
				px={4}
				borderWidth={1}
				rounded="lg"
			>
				<DocumentString<Offer>
					path={`offers/${callNote.reason.offerId}`}
					fields={["offerNumber", "customerName", "projectName"]}
					transformers={[
						{ field: "offerNumber", transformer: (value) => `${value} /` },
						{ field: "customerName", transformer: (value) => `${value} /` },
					]}
					db={db}
				/>
				<Button
					onClick={handleOnOpenOffer}
					isLoading={isLoading}
					w="max-content"
					size="sm"
					variant="ghost"
				>
					Öffnen
				</Button>
			</HStack>
		</FormControl>
	);
};
