import { moment, type Absence } from "@msuite/katana";
import { FormErrorMessage } from "@msuite/picasso";
import * as yup from "yup";

export const FormError = FormErrorMessage<Absence>;

export const absenceFormSchema = (withMultipleSelection: boolean) =>
	yup
		.object()
		.shape({
			von: yup.string().required("Von-Datum ist erforderlich"),
			bis: yup
				.string()
				.test("is-after-or-same", "Enddatum darf nicht vor dem Startdatum liegen", function (value) {
					const currentStartDate = this.parent.von;
					return moment(value).isSameOrAfter(currentStartDate);
				})
				.required("Bis-Datum ist erforderlich"),
			type: yup.string().required("Art der Abwesenheit ist erforderlich"),
			category: yup.string().when("type", {
				is: (type: string) => type === "homeoffice" || type === "azk",
				then: yup.string().notRequired(),
				otherwise: yup.string().required("Kategorie der Abwesenheit ist erforderlich"),
			}),
			status: yup.string().required("Status der Abwesenheit ist erforderlich"),
			mitarbeiterId: withMultipleSelection
				? yup.string().notRequired()
				: yup.string().required("Mitarbeiter-ID ist erforderlich"),
			comment: yup.string().optional(),
		})
		.transform((originalObject: Absence) => {
			if (originalObject.type === "homeoffice" || originalObject.type === "azk") {
				delete originalObject.category;
			}
			return originalObject;
		});
