import type {
	CallNote,
	TCallOtherReasons,
	TCallPriority,
	TCallReasons,
	TCallerContactType,
	TCallerPosition,
	TCallerTitle,
} from "@msuite/katana";
import { moment } from "@msuite/katana";
import { FormErrorMessage } from "@msuite/picasso";
import * as yup from "yup";
import { string } from "yup";

export const FormError = FormErrorMessage<CallNote>;

export const CallerPositions: TCallerPosition[] = ["ceo", "sales", "manager", "purchase", "unknown"];
export const CallOtherReasons: TCallOtherReasons[] = ["request", "appointment", "other"];
export const CallReasons: TCallReasons[] = ["construction-site", "offer", "other"];
export const CallPriorities: TCallPriority[] = ["very-high", "high", "medium", "low", "custom"];
export const CallerTitles: TCallerTitle[] = ["mr", "mrs"];
export const CallerContactTypes: TCallerContactType[] = ["email", "phone"];

export const CallReasonLabels = {
	"construction-site": "Bestehendes Bauvorhaben",
	offer: "Angebot",
	other: "Sonstiges",
};

export const CallOtherReasonLabels = {
	request: "Anfrage",
	appointment: "Terminvereinbarung",
	offer: "Angebot",
	other: "Sonstiges",
};

export const CallerPositionLabels = {
	ceo: "Geschäftsführer",
	sales: "Verkauf",
	manager: "Bauleiter",
	purchase: "Einkauf",
	unknown: "Unbekannt",
};

export const CallerTitleLabels = {
	mr: "Herr",
	mrs: "Frau",
};

export const CallPriorityLabels = {
	"very-high": "Sehr hoch",
	high: "Hoch",
	medium: "Mittel",
	low: "Niedrig",
	custom: "Eigener Rückrufwunsch",
};

export const CallerContactTypeLabels = {
	email: "E-Mail",
	phone: "Telefon",
};

export const callNoteSchema = yup
	.object()
	.transform((value: CallNote) => {
		if (!value.caller.contact) return value;
		value.caller.contact = value.caller.contact.filter((v: CallNote["caller"]["contact"][0]) => !!v.value);
		value.caller.contact = value.caller.contact.map((v) => {
			if (v.value.includes("@")) v.type = "email";
			else v.type = "phone";
			return v;
		});
		return value;
	})
	.shape({
		workspaceId: yup.string().default(undefined).required("Bitte Firma auswählen"),
		receivedAt: yup
			.string()
			.transform(() => moment().toISOString())
			.required(),
		isPrivate: yup.boolean().default(false),
		receivedBy: yup.string().required(),
		caller: yup.object().shape({
			title: yup.string().required("Bitte Anrede auswählen"),
			firstName: yup.string().notRequired(),
			lastName: yup.string().required("Bitte Nachname eingeben"),
			companyName: yup.string().optional(),
			position: yup.string().oneOf(CallerPositions).required("Bitte Position auswählen"),
			contact: yup
				.array()
				.min(1, "Bitte mindestens eine Kontaktmöglichkeit angeben")
				.of(
					yup.object().shape({
						type: yup.string().oneOf(["email", "phone"]).required("Wert üngültig"),
						value: yup
							.string()
							.when("type", {
								is: "phone",
								then: yup
									.string()
									.matches(/^\+?[0-9 -/+]+$/, "Wert ungültig.")
									.required(),
							})
							.when("type", {
								is: "email",
								then: yup.string().email("Wert ungültig.").required("Wert ungültig."),
							}),
					}),
				)
				.optional(),
		}),
		reason: yup.object().shape({
			type: yup
				.string()
				.oneOf([...CallReasons], "Ungültiger Grund")
				.required("Bitte Grund auswählen"),
			constructionSiteId: yup.string().when("type", {
				is: "construction-site",
				then: yup
					.string()
					.transform((value) => value?.trim() ?? undefined)
					.min(2)
					.required("Bitte Baustelle auswählen"),
			}),
			isUnsafe: yup.boolean().when("type", {
				is: "construction-site",
				then: yup.boolean().default(false).required(),
			}),
			offerId: yup.string().when("type", {
				is: "offer",
				then: yup
					.string()
					.transform((value) => value?.trim() ?? undefined)
					.min(2)
					.required("Bitte Angebot auswählen"),
			}),
			otherReason: string()
				.when("type", {
					is: "other",
					then: yup
						.string()
						.oneOf([...CallOtherReasons])
						.required("Bitte Grund auswählen"),
				})
				.when("type", {
					is: "construction-site",
					then: yup
						.string()
						.default("request")
						.nullable()
						.transform(() => null)
						.notRequired(),
				}),
			description: yup
				.string()
				.min(5, "Bitte geben Sie mindestens 5 Zeichen an.")
				.required("Bitte Beschreibung eingeben"),
		}),
		answerToCategory: yup.string().min(2, "Bitte Kategorie auswählen").required("Bitte Kategorie auswählen"),
		answerTo: yup.string().min(2, "Bitte Ansprechperson asuwählen").required("Bitte Ansprechperson auswählen"),
		priority: yup
			.string()
			.default("low")
			.oneOf([...CallPriorities])
			.required("Bitte Priorität auswählen"),
		callbackDate: yup
			.string()
			.test(
				"is-valid-date",
				"Datum darf nicht vor aktuellem Zeitpunkt liegen",
				(value) => moment(value).isValid() && moment().isBefore(value),
			)
			.when("priority", {
				is: "very-high",
				then: yup
					.string()
					.test(
						"is-valid-priority-date",
						"Zeitpunkt muss innerhalb der nächsten 24h liegen (Priorität: Sehr hoch)",
						(value) => moment(value).isValid() && moment().add(24, "hours").isAfter(value),
					),
			})
			.when("priority", {
				is: "high",
				then: yup
					.string()
					.test(
						"is-valid-priority-date",
						"Zeitpunkt muss innerhalb der nächsten 36h liegen (Priorität: Hoch)",
						(value) => moment(value).isValid() && moment().add(36, "hours").isAfter(value),
					),
			})
			.required("Bitte Rückrufzeitpunkt auswählen"),
		furtherNotes: yup.string().optional(),
	});
