import { useConstructionSiteCardContext } from "@/context/construction-site-card";
import { useTicketCardContext, useTicketsContext } from "@/context/ticket-card";
import { db } from "@/core";
import type { ConstructionSite } from "@msuite/katana";
import { useUIContext } from "@msuite/picasso";
import { doc, updateDoc } from "firebase/firestore";
import type { FC } from "react";
import { ActionBarButton } from "../action-bar-button";

export const DeleteTicketButton: FC = () => {
	/** Context */
	const { constructionSite, constructionSiteId, withMultiSelect } = useConstructionSiteCardContext();
	const { ticket } = useTicketCardContext();
	const { filteredTickets, setSelectedTicketIndices, selectedTicketIndices } = useTicketsContext();

	/** Constants */
	const { colors } = useUIContext();

	/** Functions */
	async function handleMultiSelect() {
		if (withMultiSelect && selectedTicketIndices?.length > 0) {
			const ticketIds = filteredTickets
				.map((ticket) => ticket.id)
				.filter((_, index) => selectedTicketIndices.includes(index));
			await handleDeleteTicket(ticketIds);
		} else {
			handleDeleteTicket([ticket.id]);
		}
		setSelectedTicketIndices([]);
	}

	async function handleDeleteTicket(ticketIds: string[]) {
		const newTickets = constructionSite?.tickets?.filter((t) => !ticketIds.includes(t.id));
		const deletedTickets = constructionSite?.tickets?.filter((t) => ticketIds.includes(t.id)) ?? [];
		const newInactive: ConstructionSite["inactive"] = [...(constructionSite?.inactive ?? []), ...deletedTickets];
		if (!newTickets) return;
		if (!constructionSiteId) return;
		const constructionSiteRef = doc(db, `baustellen/${constructionSiteId}`);
		await updateDoc(constructionSiteRef, {
			tickets: newTickets,
			inactive: newInactive,
		});
	}

	/** Render */
	return (
		<ActionBarButton
			color={colors.red}
			onClick={handleMultiSelect}
		/>
	);
};
