import { CallNoteVisibilityBadge, HStack, Text, getCallNoteVisibilityLabel } from "@msuite/picasso";
import type { FC } from "react";
import { useCallNoteOverviewContext } from "./context";

export const OverviewVisibilityInformation: FC = () => {
	/** Context */
	const { callNote } = useCallNoteOverviewContext();

	/** Computed */
	const label = getCallNoteVisibilityLabel(callNote);

	/** Render */
	return (
		<HStack spacing={3}>
			<CallNoteVisibilityBadge
				callNote={callNote}
				disableTooltip
			/>
			<Text>{label}</Text>
		</HStack>
	);
};
