import { useConstructionSiteCardContext } from "@/context/construction-site-card";
import { useTicketsContext } from "@/context/ticket-card";
import type { Ticket } from "@msuite/katana";
import { GridItem, HStack } from "@msuite/picasso";
import type { FC } from "react";
import { TicketDateBadge } from "./ticket-date-badge";

/** Props Interface */
interface TicketDateProps {
	ticket: Ticket;
	handleOnDeleteTicketDate: (ticketIds: string[], mode: TicketDateMode) => Promise<void>;
}

export type TicketDateMode = "earliest-from" | "date" | "both";

export const TicketDate: FC<TicketDateProps> = ({ ticket, handleOnDeleteTicketDate }) => {
	/** Context */
	const { withMultiSelect } = useConstructionSiteCardContext();
	const { selectedTicketIndices, setSelectedTicketIndices, filteredTickets } = useTicketsContext();

	/** Constants */
	const hasAnyDate = ticket.date || ticket.date_earliest_from;
	const hasBothDates = !!ticket.date && !!ticket.date_earliest_from;
	const hasEarliestFromOnly = !!ticket.date_earliest_from && !ticket.date;
	const hasDateOnly = !!ticket.date && !ticket.date_earliest_from;
	const hasDeadline = !!ticket.date_is_deadline;

	/** Fallback */
	if (!hasAnyDate) return null;

	/** Functions */
	async function handleMultiSelect(mode: TicketDateMode) {
		try {
			if (withMultiSelect && selectedTicketIndices?.length > 0) {
				const ticketIds = filteredTickets
					.map((ticket) => ticket.id)
					.filter((_, index) => selectedTicketIndices.includes(index));
				await handleOnDeleteTicketDate(ticketIds, mode);
			} else {
				await handleOnDeleteTicketDate([ticket.id], mode);
			}
			setSelectedTicketIndices([]);
		} catch (error) {
			console.error(error);
		}
	}

	/** Render */
	return (
		<GridItem gridColumnStart={2}>
			<HStack spacing={2}>
				{ticket.date_earliest_from && (
					<TicketDateBadge
						date={ticket.date_earliest_from}
						isDeadline={false}
						isEarliestFrom
						onDelete={handleMultiSelect}
						mode="earliest-from"
						withLabel={hasEarliestFromOnly}
						label="Frühestens ab"
					/>
				)}
				{hasBothDates && <span style={{ transform: "translateY(-0.75px)" }}>&#8250;</span>}
				{ticket.date && (
					<TicketDateBadge
						date={ticket.date}
						isDeadline={ticket.date_is_deadline}
						mode="date"
						onDelete={handleMultiSelect}
						withLabel={hasDateOnly && hasDeadline}
						label="Spätestens bis"
					/>
				)}
			</HStack>
		</GridItem>
	);
};
