import type { useColon } from "@/sites/plan/dailies/daily/daily-content/colon/use-colon";
import type { useDaily } from "@/sites/plan/dailies/daily/use-daily";
import type { useDailies } from "@/sites/plan/dailies/use-dailies";
import type { usePlan } from "@/sites/plan/use-plan";
import type { useWeekly } from "@/sites/plan/weekly/use-weekly";
import { createContext, useContext } from "react";

/** Interface */
interface IPlanContext extends ReturnType<typeof usePlan> {}
interface IWeeklyContext extends ReturnType<typeof useWeekly> {}
interface IDailiesContext extends ReturnType<typeof useDailies> {}
interface IDailyContext extends ReturnType<typeof useDaily> {}
interface IColonContext extends ReturnType<typeof useColon> {}

/** Context */
export const PlanContext = createContext<IPlanContext>({} as IPlanContext);
export const WeeklyContext = createContext<IWeeklyContext>({} as IWeeklyContext);
export const DailiesContext = createContext<IDailiesContext>({} as IDailiesContext);
export const DailyContext = createContext<IDailyContext>({} as IDailyContext);
export const ColonContext = createContext<IColonContext>({} as IColonContext);

/** Hooks */
export const usePlanContext = () => {
	return useContext(PlanContext);
};

export const useWeeklyContext = () => {
	return useContext(WeeklyContext);
};

export const useDailiesContext = () => {
	return useContext(DailiesContext);
};

export const useDailyContext = () => {
	return useContext(DailyContext);
};

export const useColonContext = () => {
	return useContext(ColonContext);
};
