import type { ConstructionSite, Ticket } from "@msuite/katana";
import { generateId } from "@msuite/katana";
import { useLayoutEffect, useState } from "react";
import type { useForm } from "react-hook-form";
import type { ConstructionSiteFormView } from "./construction-site-form";

const SEGMENTED_CONTROL_OPTIONS = [
	{ label: "Bauvorhaben", value: "construction-site" },
	{ label: "Tickets", value: "tickets" },
];

const SEGMENTED_CONTROL_OPTIONS_WITH_EXISTING_SEARCHBOX = [
	{ label: "Suche", value: "existing-search-box" },
	{ label: "Bauvorhaben", value: "construction-site" },
	{ label: "Tickets", value: "tickets" },
];

export const useConstructionSiteForm = (props?: {
	withExistingSearchBox?: boolean;
	isOpen?: boolean;
	methods: ReturnType<typeof useForm<ConstructionSite>>;
}) => {
	const { withExistingSearchBox, isOpen } = props || {
		withExistingSearchBox: false,
		isOpen: false,
	};
	const DEFAULT_VIEW: ConstructionSiteFormView = withExistingSearchBox ? "existing-search-box" : "construction-site";

	/** State */
	const [currentView, setCurrentView] = useState<ConstructionSiteFormView>(DEFAULT_VIEW);
	const [initialFocusTicketId, setInitialFocusTicketId] = useState<string | undefined>(undefined);

	/** Effects */
	useLayoutEffect(() => {
		try {
			if (!isOpen) {
				if (initialFocusTicketId) {
					setInitialFocusTicketId(undefined);
				}
				return;
			}
			const localStorageItem = "construction-site-edit-initial-ticket";
			let ticketId = localStorage.getItem(localStorageItem);
			if (ticketId === "new-ticket") {
				ticketId = generateId();
				const ticketData = localStorage.getItem("construction-site-edit-initial-ticket-data");
				const parsedTicket = JSON.parse(ticketData ?? "{}") as Ticket;
				parsedTicket.id = ticketId;
				if (!parsedTicket.id) throw new Error("Ticket has no id");
				props?.methods.setValue("tickets", [...(props?.methods.getValues("tickets") ?? []), parsedTicket]);
			}
			if (ticketId) {
				setCurrentView("tickets");
				setInitialFocusTicketId(ticketId);
			}
			localStorage.removeItem(localStorageItem);
		} catch (error) {
			console.error(error);
		}
	}, [isOpen]);

	/** Return */
	return {
		currentView,
		setCurrentView,
		DEFAULT_VIEW,
		initialFocusTicketId,
		SEGMENTED_CONTROL_OPTIONS: withExistingSearchBox
			? SEGMENTED_CONTROL_OPTIONS_WITH_EXISTING_SEARCHBOX
			: SEGMENTED_CONTROL_OPTIONS,
	};
};
