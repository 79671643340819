import { db } from "@/core";
import { TicketSchema, TicketSchemaWithDefaults } from "@/forms/tickets-form/schema";
import { cleanUndefinedValues, type ConstructionSite } from "@msuite/katana";
import { FormErrorMessage } from "@msuite/picasso";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import * as yup from "yup";

export const FormError = FormErrorMessage<ConstructionSite>;

export const ConstructionSiteSchema = yup
	.object()
	.transform((values: Partial<ConstructionSite>) => {
		if (!values.clientId) delete values.clientId;
		if (!values.clientId && !values.auftraggeber) {
			values.auftraggeber = "Bitte ergänzen";
		}
		if (values.clientId) {
			delete values.auftraggeber;
		}
		const constructionSite = cleanUndefinedValues(values) as typeof values;
		constructionSite.tickets = constructionSite.tickets?.map(cleanUndefinedValues);
		constructionSite.inactive = constructionSite.inactive?.map(cleanUndefinedValues);
		return values;
	})
	.shape({
		id: yup.string().optional(),
		bauvorhaben: yup.string().required("Bitte Bauvorhaben angeben"),
		clientId: yup
			.string()
			.test("has-legacy-client", "Bitte Auftraggeber auswählen.", async function () {
				const parent = this.parent as ConstructionSite;
				if (!parent.clientId && !parent.auftraggeber) return false;
				return true;
			})
			.notRequired(),
		nummer: yup
			.string()
			.transform((value) => {
				const transformed = value.trim().replaceAll(" ", "");
				if (transformed.length === 7) {
					// string should look like 2221111 -> 222 1111
					return `${transformed.substr(0, 3)} ${transformed.substr(3)}`;
				}
				if (transformed.length === 8) {
					// string should look like W2221111 -> W222 1111
					return `${transformed.substr(0, 4)} ${transformed.substr(4)}`;
				}
				return transformed;
			})
			.test("is-real-number", "W-Nummern können nicht selbst vergeben werden", async function (value) {
				const { path, createError } = this;
				const currentId = this.parent.id;
				if (!currentId) return true;
				const currentDoc = (await getDoc(doc(db, "baustellen", currentId))).data() as ConstructionSite;
				if (value !== currentDoc.nummer) {
					if (value?.startsWith("W")) {
						return createError({
							path,
							message: "W-Nummern können nicht selbst vergeben werden.",
						});
					}
				}
				return true;
			})
			.test("is-unique", "Die Baustellennummer muss eindeutig sein", async function (value) {
				const { path, createError } = this;
				const workspaceId = this.parent.plangruppe;
				const currentId = this.parent.id;
				if (!value) return true;
				const existingDocsRef = query(
					collection(db, "baustellen"),
					where("nummer", "==", value),
					where("plangruppe", "==", workspaceId),
				);
				const existingDocs = (await getDocs(existingDocsRef)).docs.filter((doc) => doc.id !== currentId);
				if (existingDocs.length > 0) {
					return createError({
						path,
						message: "Die Projektnummer wurde bereits vergeben.",
					});
				}
				return true;
			})
			.notRequired(),
		strasse: yup.string().required("Bitte Straße angeben"),
		hausnummer: yup.string().default("").notRequired(),
		plz: yup.string().max(5).required("Bitte PLZ angeben"),
		ort: yup.string().required("Bitte Ort angeben"),
		currentDeputies: yup.array().of(yup.string()).notRequired(),
		bauleiter: yup.array().min(1, "Mindestens einen Bauleiter auswählen").of(yup.string()).required().default([]),
		tickets: yup.array().of(TicketSchema).notRequired(),
		inactive: yup.array().of(TicketSchemaWithDefaults).notRequired(),
		plangruppe: yup.string().required("Bitte Plangruppe angeben"),
		isInactive: yup.boolean(),
		note: yup.string().notRequired(),
	});
