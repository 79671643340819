import { findTicket } from "@/components/card/construction-site-card/construction-site-card";
import { db } from "@/core";
import type { ConstructionSite, DailyColonPlanConstructionSite } from "@msuite/katana";
import { HStack, Text, TicketTypeIcon, VStack, useDocument, useUIContext } from "@msuite/picasso";
import { type FC, useMemo } from "react";

/** Props Interface */
interface QuickAssignItemConstructionSiteProps {
	dailyConstructionSite: DailyColonPlanConstructionSite;
}

export const QuickAssignItemConstructionSite: FC<QuickAssignItemConstructionSiteProps> = ({
	dailyConstructionSite,
}) => {
	/** Context */
	const { colors } = useUIContext();

	/** Hooks */
	const { data: constructionSite } = useDocument<ConstructionSite>(db, {
		path: `baustellen/${dailyConstructionSite.id}`,
		subscribe: false,
	});

	const ticket = useMemo(() => {
		if (!constructionSite) return undefined;
		return findTicket(constructionSite, dailyConstructionSite.ticket);
	}, [constructionSite, dailyConstructionSite.ticket]);

	if (!ticket) return null;

	/** Render */
	return (
		<VStack
			fontSize="xs"
			spacing={0}
			opacity={0.65}
			_hover={{
				opacity: 1,
			}}
		>
			<Text fontWeight="medium">{constructionSite?.bauvorhaben}</Text>
			<HStack spacing={1}>
				<TicketTypeIcon type={ticket.type} />
				<Text
					color={colors.gray}
					noOfLines={1}
				>
					{ticket.ticket}
				</Text>
			</HStack>
		</VStack>
	);
};
