import { Center, Text, useUIContext } from "@msuite/picasso";
import type { FC } from "react";

/** Props Interface */
interface TicketFormPlaceholderProps {
	onClick?: () => void;
}

export const TicketFormPlaceholder: FC<TicketFormPlaceholderProps> = ({ onClick }) => {
	/** Context */
	const { colors } = useUIContext();

	/** Render */
	return (
		<Center
			borderWidth={0.5}
			borderStyle="dashed"
			rounded="md"
			p={6}
			backgroundColor={colors.whiteAdjusted}
			cursor="pointer"
			onClick={onClick}
			_hover={{ opacity: 0.8 }}
		>
			<Text fontSize="xs">Keine Tickets vorhanden</Text>
		</Center>
	);
};
