import { moment } from "@msuite/katana";
import { HStack, Text, useColorModeValue, useHover, useUIContext } from "@msuite/picasso";
import { motion } from "framer-motion";
import { type FC, useRef } from "react";
import { TbTrash } from "react-icons/tb";
import type { TicketDateMode } from "./ticket-date";

/** Props Interface */
interface TicketDateBadgeProps {
	date: string;
	isDeadline?: boolean;
	isEarliestFrom?: boolean;
	mode: TicketDateMode;
	onDelete: (mode: TicketDateMode) => Promise<void>;
	withLabel: boolean;
	label: string;
}

export const TicketDateBadge: FC<TicketDateBadgeProps> = ({ date, withLabel, label, mode, onDelete }) => {
	/** State */
	const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
	const { isHovered } = useHover(ref);

	/** Context */
	const { colors } = useUIContext();

	/** Hooks */
	const fontColor = useColorModeValue("gray.700", "gray.300");

	/** Constants */
	const ticketDateString = moment().convertTicketDateToString(date, "ddd, DD.MM.YYYY");

	/** Render */
	return (
		<div ref={ref}>
			<HStack
				fontSize="sm"
				cursor="pointer"
				spacing={0}
				color={isHovered ? colors.red : fontColor}
				onClick={() => onDelete(mode)}
			>
				{withLabel && <Text mr={1}>{label}</Text>}
				<Text>{ticketDateString}</Text>
				<motion.div
					animate={{ width: isHovered ? "auto" : 0 }}
					style={{ overflow: "hidden", display: "flex", flexDirection: "row", width: 0 }}
					transition={{ duration: 0.2 }}
				>
					<div style={{ width: "0.5rem" }} />
					<div style={{ transform: "translateY(-0.5px)" }}>
						<TbTrash />
					</div>
				</motion.div>
			</HStack>
		</div>
	);
};
