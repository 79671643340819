import { db } from "@/core";
import type { DailyColonPlan, Dayinfo } from "@msuite/katana";
import { useCollection, useDocument } from "@msuite/picasso";
import { useDailiesContext, usePlanContext } from "context/plan";
import { useCallback, useEffect, useMemo, useState } from "react";
import { usePublishListener } from "./use-publish-listener";

/** Interface */
interface UseDailyOptions {
	dailyIndex: number;
}

export function getDailyReferenceString(weeklyReference: string, dailyMoment: moment.Moment) {
	return `${weeklyReference}/tage/${dailyMoment.format("DDMMYYYY")}`;
}

export const useDaily = ({ dailyIndex }: UseDailyOptions) => {
	/** Context */
	const { startOfWeekMoment } = useDailiesContext();
	const { activeDate, weeklyReference, setActiveDailyInfo, setActiveDailyColons } = usePlanContext();

	/** State */
	const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
	const [colonsAreShrinked, setColonsAreShrinked] = useState<boolean>(false);
	const [showSparetimeOnly, setShowSparetimeOnly] = useState<boolean>(false);
	const [showAbsences, setShowAbsences] = useState<boolean>(false);

	/** Constants */
	const dailyMoment = startOfWeekMoment.clone().add(dailyIndex, "days");
	const isActive = dailyMoment.isSame(activeDate, "day");
	const dailyReference = getDailyReferenceString(weeklyReference, dailyMoment);

	/** Hooks */
	const { isPublished, isEditing, publishedDay, isBlocked } = usePublishListener(dailyMoment);
	const { data: dailyInfo } = useDocument<Dayinfo>(db, {
		path: dailyReference,
		subscribe: true && !isCollapsed,
	});
	const { data: _dailyColons } = useCollection<DailyColonPlan>(db, {
		path: `${dailyReference}/kolonnen`,
		subscribe: true && !isCollapsed,
	});

	/** Functions */
	const sortDailyColons = useCallback(() => {
		const __dailyColons = _dailyColons ?? [];
		__dailyColons
			.sort((a, b) => (a.rating ?? 0) - (b.rating ?? 0))
			.sort((a, b) => (a.name ?? "").localeCompare(b.name ?? ""));
		const ownColons = __dailyColons.filter((colon) => colon.name === "Kolonne");
		const protectedColons = __dailyColons.filter((colon) => ["Platz", "Aufzug"].includes(colon.name ?? ""));
		const subColons = __dailyColons.filter((colon) => !["Kolonne", "Platz", "Aufzug"].includes(colon.name ?? ""));
		return {
			ownColons,
			protectedColons,
			subColons,
		};
	}, [_dailyColons]);

	/** Memoized */
	const dailyColons = useMemo(() => sortDailyColons(), [sortDailyColons]);

	/** Effects */
	useEffect(() => {
		if (!isActive) return;
		setActiveDailyInfo(dailyInfo);
		setActiveDailyColons(Object.values(dailyColons).flat());
	}, [isActive, dailyColons, dailyInfo, setActiveDailyColons, setActiveDailyInfo]);

	useEffect(() => {
		if (!isActive) return;
		if (isCollapsed) {
			setActiveDailyInfo(undefined);
			setActiveDailyColons([]);
		} else {
			setActiveDailyInfo(dailyInfo);
			setActiveDailyColons(Object.values(dailyColons).flat());
		}
	}, [isCollapsed, isActive, setActiveDailyColons, setActiveDailyInfo, dailyColons, dailyInfo]);

	/** Render */
	return {
		dailyIndex,
		dailyMoment,
		dailyInfo,
		isCollapsed,
		setIsCollapsed,
		colonsAreShrinked,
		setColonsAreShrinked,
		isActive,
		dailyReference,
		dailyColons,
		showSparetimeOnly,
		setShowSparetimeOnly,
		isPublished,
		isEditing,
		publishedDay,
		isBlocked,
		showAbsences,
		setShowAbsences,
	};
};
