import { db } from "@/core";
import type { ConstructionSite, SubcontractorTimesheetSection } from "@msuite/katana";
import { HStack, Text, VStack, useDocument } from "@msuite/picasso";
import type { FC } from "react";
import { ConstructionSiteEntry } from "./subcontractor-section-entry";

/** Props Interface */
interface SubcontractorSectionConstructionSiteHeaderProps {
	subcontractorId: string;
	constructionSiteSection: Required<SubcontractorTimesheetSection>["baustellen"][number];
}

export const SubcontractorSectionConstructionSiteHeader: FC<SubcontractorSectionConstructionSiteHeaderProps> = ({
	constructionSiteSection,
	subcontractorId,
}) => {
	/** Context */
	const { data: constructionSite } = useDocument<ConstructionSite>(db, {
		path: `baustellen/${constructionSiteSection.baustelle_id}`,
	});

	/** Render */
	return (
		<VStack spacing={0}>
			<HStack
				ml={6}
				pr={3}
				justifyContent="space-between"
				paddingY={3}
				borderTopWidth={1}
			>
				<Text
					flex={1}
					noOfLines={1}
					fontWeight="semibold"
				>
					{constructionSite?.bauvorhaben}
				</Text>
				<Text width="max-content">{constructionSite?.nummer}</Text>
			</HStack>
			{constructionSiteSection?.entries?.map((entry) => (
				<ConstructionSiteEntry
					key={entry.id}
					entry={entry}
					constructionSiteId={constructionSiteSection.baustelle_id}
					subcontractorId={subcontractorId}
				/>
			))}
		</VStack>
	);
};
