import { auth, ax, db, queryClient, storage } from "@/core";
import { AuthProvider, CoreProvider, QueryClientProvider, ThemeProvider } from "@msuite/picasso";
import ReactDOM from "react-dom/client";
import packageJson from "../package.json";
import { App } from "./app";

/** React */
const root = document.getElementById("root");

if (root) {
	ReactDOM.createRoot(root).render(
		<CoreProvider value={{ ax, auth, storage, db, version: packageJson.version }}>
			<QueryClientProvider client={queryClient}>
				<AuthProvider>
					<ThemeProvider titlePrefix="MenkeSuite">
						<App />
					</ThemeProvider>
				</AuthProvider>
			</QueryClientProvider>
		</CoreProvider>,
	);
}
