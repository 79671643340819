import { db, queryClient } from "@/core";
import { moment, type Absence } from "@msuite/katana";
import {
	BlankSlateContainer,
	Button,
	ContentContainerBody,
	ContentContainerFooter,
	ContentContainerHeading,
	ModalControllerContainer,
	toast,
	useAsyncModal,
	useAuthContext,
	useDisclosure,
	useDocument,
	useVacationsTileStore,
} from "@msuite/picasso";
import { doc, updateDoc } from "firebase/firestore";
import { Fragment, useState, type FC } from "react";
import { EditAbsenceModal } from "../edit-absence-modal";
import { AbsenceOverview } from "./absence-overview";

/** Props Interface */
interface ControlProps {
	onClose: () => void;
	employeeId: string;
}

export const Control: FC<ControlProps> = ({ onClose, employeeId }) => {
	/** State */
	const { selectedAbsenceId } = useVacationsTileStore();
	const [isCancelLoading, setIsCancelLoading] = useState<boolean>(false);

	/** Context */
	const { validation, userId } = useAuthContext();

	/** Hooks */
	const asyncModal = useAsyncModal();
	const editAbsenceModal = useDisclosure();
	const { data: absence } = useDocument<Absence>(db, {
		path: `mitarbeiter/${employeeId}/abwesenheit/${selectedAbsenceId}`,
		subscribe: true,
	});

	if (!selectedAbsenceId)
		return (
			<BlankSlateContainer
				heading="Keine Abwesenheit ausgewählt"
				subtext="Wählen Sie eine Abwesenheit aus oder beantragen Sie eine."
			/>
		);

	if (!absence) {
		return (
			<BlankSlateContainer
				heading="Keine Abwesenheit gefunden"
				subtext="Die Abwesenheit konnte nicht gefunden werden."
			/>
		);
	}

	const editableStatuses: Array<Absence["status"]> = ["gesehen", "eingereicht"];
	const cancelableStatuses: Array<Absence["status"]> = ["gesehen", "eingereicht"];
	const isEditable = editableStatuses.includes(absence.status) || validation?.isPersonal;
	const isCancelable = cancelableStatuses.includes(absence.status) || validation?.isPersonal;

	/** Functions */
	async function refetchAbsenceList() {
		await queryClient.invalidateQueries(["dashboard", "absences"]);
	}

	async function handleOnCancel() {
		try {
			setIsCancelLoading(true);
			if (!absence) throw new Error("Absence not found");
			if (!userId) throw new Error("User not found");
			const absenceRef = doc(db, `mitarbeiter/${employeeId}/abwesenheit/${absence.id}`);
			const updateObject: Partial<Absence> = {
				updated_at: moment().toISOString(),
				updated_by: userId,
				status: "canceled",
				reason_cancelation: "Stornierung durch Mitarbeiter",
			};
			const isConfirmed = await asyncModal({
				headerText: "Abwesenheit stornieren",
				bodyText: "Möchten Sie diese Abwesenheit wirklich stornieren?",
			});
			if (!isConfirmed) return;
			await updateDoc(absenceRef, { ...updateObject });
			await refetchAbsenceList();
			toast.success("Abwesenheit erfolgreich storniert");
		} catch (error) {
			toast.error("Fehler beim Stornieren der Abwesenheit");
			console.error(error);
		} finally {
			setIsCancelLoading(false);
		}
	}

	/** Render */
	return (
		<Fragment>
			<ModalControllerContainer>
				<ContentContainerHeading>
					{moment().getSpanString(moment(absence.von), moment(absence.bis))}
				</ContentContainerHeading>
				<ContentContainerBody>
					<AbsenceOverview
						absence={absence}
						employeeId={employeeId}
					/>
				</ContentContainerBody>
				<ContentContainerFooter>
					{isCancelable && (
						<Button
							colorScheme="red"
							variant="ghost"
							onClick={handleOnCancel}
							isLoading={isCancelLoading}
						>
							Stornieren
						</Button>
					)}
					{isEditable && <Button onClick={editAbsenceModal.onOpen}>Bearbeiten</Button>}
					<Button onClick={onClose}>Fertig</Button>
				</ContentContainerFooter>
			</ModalControllerContainer>
			{editAbsenceModal.isOpen && isEditable && (
				<EditAbsenceModal
					absenceId={absence.id}
					employeeId={employeeId}
					isOpen={editAbsenceModal.isOpen}
					onClose={editAbsenceModal.onClose}
					onComplete={refetchAbsenceList}
				/>
			)}
		</Fragment>
	);
};
