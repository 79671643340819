import { moment } from "@msuite/katana";
import { Box, Grid, GridItem, IconButton, Text, useUIContext } from "@msuite/picasso";
import type { StorageReference } from "firebase/storage";
import { type FC, useState } from "react";
import { TbTrash } from "react-icons/tb";
import { FileIconContainer } from "./file-icon-container";
import { UploadAnimationBar } from "./upload-animation-bar";

/** Props Interface */
interface FileCardProps {
	file: File;
	storageRef?: StorageReference;
	index?: number;
	isContentEditable?: boolean;
	onRemove?: () => void;
	onRename?: (index: number, name: string) => void;
	withUploadAnimation?: boolean;
	style?: React.CSSProperties;
	iconOnly?: boolean;
}

export const FileCard: FC<FileCardProps> = ({
	file,
	storageRef,
	isContentEditable,
	index,
	onRemove,
	onRename,
	withUploadAnimation = true,
	style,
	iconOnly,
}) => {
	/** Context */
	const { colors } = useUIContext();

	/** State */
	const [isInitiallyLoaded, setIsInitiallyLoaded] = useState<boolean>(false);
	const [changedFileName, setChangedFileName] = useState<string>(file.name);

	function handleOnRename() {
		if (typeof index !== "number") return;
		const newFileName = changedFileName.trim().replaceAll(" ", "-");
		onRename?.(index, newFileName);
	}

	/** Fallback */
	if (iconOnly) {
		return (
			<Box
				borderWidth={1}
				rounded="lg"
				overflow="hidden"
				width="6rem"
				style={{ aspectRatio: "1" }}
			>
				<FileIconContainer
					file={file}
					storageRef={storageRef}
				/>
			</Box>
		);
	}

	/** Render */
	return (
		<Grid
			backgroundColor={colors.whiteAdjusted}
			rounded="md"
			borderWidth={1}
			templateColumns="4rem 1fr"
			columnGap={3}
			overflow="hidden"
			position="relative"
			style={style}
		>
			<FileIconContainer
				file={file}
				storageRef={storageRef}
			/>
			<Grid
				py={3}
				templateColumns="1fr max-content"
				templateRows="repeat(2, 1fr)"
			>
				<GridItem
					colStart={1}
					rowStart={1}
				>
					<Text
						fontWeight="medium"
						contentEditable={isContentEditable}
						display="inline"
						onInput={(event) => setChangedFileName(event.currentTarget.textContent ?? "")}
						onBlur={handleOnRename}
					>
						{file.name}
					</Text>
				</GridItem>
				<GridItem
					colStart={1}
					rowStart={2}
				>
					<Text
						fontSize="sm"
						color="gray.500"
					>
						{moment(file.lastModified).format("LLLL")}
					</Text>
				</GridItem>
				{onRemove && (
					<GridItem
						placeSelf="center"
						colStart={2}
						rowSpan={2}
						px={4}
					>
						<IconButton
							onClick={() => onRemove()}
							icon={<TbTrash />}
							aria-label="Datei löschen"
							colorScheme="red"
							rounded="full"
							variant="ghost"
						/>
					</GridItem>
				)}
			</Grid>
			{withUploadAnimation && (
				<UploadAnimationBar
					isInitiallyLoaded={isInitiallyLoaded}
					setIsInitiallyLoaded={setIsInitiallyLoaded}
				/>
			)}
		</Grid>
	);
};
