import { ax, db } from "@/core";
import {
	type Absence,
	type Employee,
	absenceCategoryToReadable,
	absenceStatusToReadable,
	absenceTypeToReadable,
	moment,
} from "@msuite/katana";
import {
	AbsenceStatusBadge,
	Divider,
	DocumentString,
	Grid,
	GridItem,
	HStack,
	Label,
	Text,
	VStack,
	useApi,
} from "@msuite/picasso";
import type { FC } from "react";

/** Props Interface */
interface AbsenceOverviewProps {
	absence: Absence;
	employeeId: string;
}

export const AbsenceOverview: FC<AbsenceOverviewProps> = ({ absence, employeeId }) => {
	/** Hooks */
	const { data } = useApi<{ days: number }>(ax, {
		url: `/v2/employees/${employeeId}/absence/${absence.id}/calculate-days`,
		dependencies: ["absence", "days", absence.id ?? "no-id"],
	});

	/** Render */
	return (
		<Grid
			templateColumns="repeat(3, 1fr)"
			gap={6}
		>
			<VStack spacing={1}>
				<Label>Von</Label>
				<Text>{moment(absence.von).format("dddd, L")}</Text>
			</VStack>
			<VStack spacing={1}>
				<Label>Bis</Label>
				<Text>{moment(absence.bis).format("dddd, L")}</Text>
			</VStack>
			<div />
			<GridItem colSpan={3}>
				<VStack spacing={1}>
					<Label>Urlaubstage</Label>
					<Text>{data?.days ?? 0}</Text>
				</VStack>
			</GridItem>
			<GridItem colSpan={3}>
				<Divider />
			</GridItem>
			<VStack spacing={1}>
				<Label>Typ</Label>
				<Text>{absenceTypeToReadable(absence.type)}</Text>
			</VStack>
			<VStack spacing={1}>
				<Label>Kategorie</Label>
				<Text>{absenceCategoryToReadable(absence.category)}</Text>
			</VStack>
			<div />
			<GridItem colSpan={3}>
				<Divider />
			</GridItem>
			<VStack spacing={1}>
				<Label>Status</Label>
				<HStack spacing={2}>
					<Text>{absenceStatusToReadable(absence.status)}</Text>
					<AbsenceStatusBadge absence={absence} />
				</HStack>
			</VStack>
			<VStack spacing={1}>
				<Label>Bearbeitet von</Label>
				{absence.updated_by ? (
					<DocumentString<Employee>
						fields={["vorname", "nachname"]}
						db={db}
						path={`mitarbeiter/${absence.updated_by}`}
					/>
				) : (
					"--"
				)}
			</VStack>
			<div />
			<GridItem colSpan={3}>
				<VStack spacing={1}>
					<Label>Kommentar</Label>
					<Text>{absence.comment || "Kein Kommentar"}</Text>
				</VStack>
			</GridItem>
		</Grid>
	);
};
