import type { FC } from "react";

/** Props Interface */
interface SketchupConnectIFrameProps {
	trimbleLink: string;
}

export const SketchupConnectIFrame: FC<SketchupConnectIFrameProps> = ({ trimbleLink }) => {
	/** Render */
	return (
		<iframe
			src={trimbleLink}
			sandbox="allow-scripts allow-modals allow-forms allow-same-origin"
			title="Trimble Connect"
			style={{
				width: "100%",
				height: "70vh",
				borderWidth: "1px",
				overflow: "hidden",
				borderRadius: "0.5rem",
			}}
		/>
	);
};
