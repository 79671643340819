import { db } from "@/core";
import type { Subcontractor } from "@msuite/katana";
import { HStack, Label, useDocument, useUIContext } from "@msuite/picasso";
import type { FC } from "react";

/** Props Interface */
interface SubcontractorSectionHeaderProps {
	subcontractorId: string;
}

export const SubcontractorSectionHeader: FC<SubcontractorSectionHeaderProps> = ({ subcontractorId }) => {
	/** Context */
	const { colors } = useUIContext();

	/** Hooks */
	const { data: subcontractor } = useDocument<Subcontractor>(db, {
		path: `unternehmer/${subcontractorId}`,
	});

	/** Render */
	return (
		<HStack
			justify="space-between"
			paddingLeft={4}
			paddingRight={6}
			paddingBottom={3}
		>
			<Label
				color={colors.black}
				fontSize="md"
				fontWeight="bold"
			>
				{subcontractor?.name}
			</Label>
		</HStack>
	);
};
