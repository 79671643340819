import { useConstructionSiteCardContext } from "@/context/construction-site-card";
import { Box, HStack, Spacer, Text, toast, useUIContext } from "@msuite/picasso";
import { type FC, useState } from "react";
import { TbCircleDashed } from "react-icons/tb";

export const NoTicketsAvailable: FC = () => {
	/** Context */
	const { colors } = useUIContext();
	const { onRemoveConstructionSite } = useConstructionSiteCardContext();

	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);

	/** Functions */
	async function handleOnDelete() {
		try {
			setIsLoading(true);
			await onRemoveConstructionSite?.();
			toast.success("Baustelle erfolgreich entfernt");
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}

	/** Render */
	return (
		<>
			<HStack
				px={4}
				py={2}
				backgroundColor={colors.grayLight}
				borderWidth={0.5}
				borderLeftWidth={12}
				rounded="sm"
				fontSize="sm"
				spacing={2}
				alignItems="center"
			>
				<TbCircleDashed />
				<Text fontWeight="medium">Keine Tickets in dieser Woche vorhanden</Text>
				<Spacer />
				{onRemoveConstructionSite && (
					<Text
						onClick={handleOnDelete}
						fontWeight="medium"
						color="red.500"
						cursor="pointer"
						_hover={{ opacity: 0.8 }}
						_active={{ opacity: 0.6 }}
					>
						{isLoading ? "Wird entfernt..." : "Entfernen"}
					</Text>
				)}
			</HStack>
			<Box sx={{ h: 2 }} />
		</>
	);
};
