import { FileGalleryModalContext } from "@/context/file-gallery-modal";
import { storage } from "@/core";
import { type CustomMediaMetadata, moment } from "@msuite/katana";
import {
	Button,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	VStack,
	toast,
} from "@msuite/picasso";
import { type StorageReference, type UploadMetadata, type UploadResult, ref, uploadBytes } from "firebase/storage";
import { type FC, useState } from "react";
import { handleHeicConversion } from "util/file";
import { Gallery } from "./gallery";
import { Upload } from "./upload";

/** Props Interface */
interface FileGalleryModalProps {
	isOpen: boolean;
	onClose: () => void;
	path: string;
	onComplete?: (results: (UploadResult | undefined)[]) => void;
	createCustomMediaMetadata?: (constructionSiteId: string, ticketId: string) => CustomMediaMetadata;
	existingFileNames: string[];
	onDeleteCallback: (fileName: string) => void;
	constructionSiteId?: string;
	ticketId?: string;
}

function alternateCreateCustomMediaMetadata() {
	const customMediaMetadata: CustomMediaMetadata = {
		timestamp: moment().toISOString(),
		mediaType: "misc",
	};
	return customMediaMetadata;
}

export const FileGalleryModal: FC<FileGalleryModalProps> = ({
	isOpen,
	onClose,
	path,
	onComplete,
	createCustomMediaMetadata = alternateCreateCustomMediaMetadata,
	existingFileNames,
	onDeleteCallback,
	constructionSiteId,
	ticketId,
}) => {
	/** State */
	const [files, setFiles] = useState<File[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	/** Functions */
	async function handleOnClose() {
		try {
			setIsLoading(true);
			if (files?.length) {
				toast.promise(handleFilesUpload, {
					loading: "Dateien werden hochgeladen...",
					success: () => {
						setIsLoading(false);
						return "Dateien wurden erfolgreich hochgeladen.";
					},
					error: () => {
						setIsLoading(false);
						return "Dateien konnten nicht hochgeladen werden.";
					},
				});
			}
			onClose();
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}

	async function handleFilesUpload() {
		try {
			const convertedFiles = await handleHeicConversion(files);
			const promises: Promise<UploadResult | undefined>[] = [];
			const storageRef = ref(storage, path);
			for (const file of convertedFiles) {
				promises.push(handleFileUpload(storageRef, file));
			}
			const results = await Promise.all(promises);
			onComplete?.(results);
			setFiles([]);
		} catch (error) {
			console.error(error);
		}
	}

	async function handleFileUpload(storageRef: StorageReference, file: File) {
		try {
			setIsLoading(true);
			const fileRef = ref(storageRef, file.name);
			let customMediaMetadata: CustomMediaMetadata | undefined;
			if (constructionSiteId && ticketId) {
				customMediaMetadata = createCustomMediaMetadata(constructionSiteId, ticketId);
			} else {
				customMediaMetadata = alternateCreateCustomMediaMetadata();
			}
			const uploadResult = await uploadBytes(fileRef, file, {
				customMetadata: customMediaMetadata as unknown as UploadMetadata["customMetadata"],
			});
			return uploadResult;
		} catch (error) {
			console.error(error);
			return undefined;
		} finally {
			setIsLoading(false);
		}
	}

	/** Render */
	return (
		<FileGalleryModalContext.Provider value={{ files, setFiles, existingFileNames, path, onDeleteCallback }}>
			<Modal
				isOpen={isOpen}
				onClose={handleOnClose}
				closeOnEsc={false}
			>
				<ModalOverlay />
				<ModalContent maxW="70vw">
					<ModalCloseButton />
					<ModalHeader>Dateien</ModalHeader>
					<ModalBody>
						<VStack>
							<Upload />
							{(existingFileNames?.length ?? 0) && <Gallery />}
						</VStack>
					</ModalBody>
					<ModalFooter>
						<ModalButtonGroup>
							{(files?.length ?? 0) > 0 && (
								<Button
									variant="ghost"
									onClick={onClose}
								>
									abbrechen
								</Button>
							)}
							<Button
								isLoading={isLoading}
								onClick={files?.length ? handleFilesUpload : handleOnClose}
							>
								{files?.length ? "Hochladen" : "Schließen"}
							</Button>
						</ModalButtonGroup>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</FileGalleryModalContext.Provider>
	);
};
