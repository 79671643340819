import { FormControl, FormHelperText, FormLabel, Text } from "@msuite/picasso";
import type { FC } from "react";
import { useCallNoteOverviewContext } from "./context";

export const OverviewFurtherNotes: FC = () => {
	/** Context */
	const { callNote } = useCallNoteOverviewContext();

	/** Render */
	return (
		<FormControl>
			<FormLabel>Rückrufnotiz</FormLabel>
			<Text>{callNote.furtherNotes || "Keine weiteren Bemerkungen"}</Text>
			<FormHelperText>
				Diese Bemerkung wird nur intern weitergeleitet und ist für den Kunden nicht sichtbar.
			</FormHelperText>
		</FormControl>
	);
};
