import { FileCardUploaded } from "@/components/card/file-card";
import { useFileGalleryModalContext } from "@/context/file-gallery-modal";
import { Grid, Label, VStack } from "@msuite/picasso";
import type { FC } from "react";

export const Gallery: FC = () => {
	/** Context */
	const { existingFileNames, path } = useFileGalleryModalContext();

	/** Render */
	return (
		<VStack spacing={2}>
			<Label>Galerie</Label>
			<Grid
				gap={4}
				templateColumns="repeat(auto-fill, minmax(8rem, 1fr))"
			>
				{existingFileNames.map((fileName, i) => (
					<FileCardUploaded
						fileName={fileName}
						path={path}
						key={fileName + i * 2}
					/>
				))}
			</Grid>
		</VStack>
	);
};
