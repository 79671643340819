import { HStack, Text } from "@msuite/picasso";
import type { FC } from "react";
import { IoAdd } from "react-icons/io5";

/** Props Interface */
interface QuickAddTicketButtonProps {
	onClick: () => void;
}

export const QuickAddTicketButton: FC<QuickAddTicketButtonProps> = ({ onClick }) => {
	return (
		<HStack
			fontSize="sm"
			px={2}
			py={1}
			spacing={2}
			cursor="pointer"
			onClick={onClick}
			fontWeight="medium"
			whiteSpace="nowrap"
		>
			<IoAdd />
			<Text>Ticket hinzufügen</Text>
		</HStack>
	);
};
