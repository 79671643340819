import type { CallNote, DashboardCallNotes } from "@msuite/katana";
import { createContext, useContext } from "react";

/** Interface */
interface IProcessCallNotesModalContext extends Pick<DashboardCallNotes, "callNotes" | "unansweredCallNotes"> {
	selectedCallNote: CallNote | undefined;
	setSelectedCallNote: (callNote: CallNote | undefined) => void;
	queryLimit: number;
	setQueryLimit: (queryLimit: number) => void;
	refetch: () => Promise<unknown>;
}

/** Context */
export const ProcessCallNotesModalContext = createContext<IProcessCallNotesModalContext>(
	{} as IProcessCallNotesModalContext,
);

/** Hooks */
export const useProcessCallNotesModalContext = () => {
	return useContext(ProcessCallNotesModalContext);
};
