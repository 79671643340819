import { motion } from "framer-motion";
import type { FC } from "react";
import { IoScan } from "react-icons/io5";

/** Props Interface */
interface MultiSelectIndicatorProps {
	isSelected: boolean;
	withMultiSelect: boolean | undefined;
}

export const MultiSelectIndicator: FC<MultiSelectIndicatorProps> = ({ isSelected, withMultiSelect }) => {
	/** Render */
	if (!withMultiSelect) return null;

	/** Render */
	return (
		<motion.div
			style={{
				overflowX: "hidden",
				width: 0,
			}}
			animate={{
				width: isSelected ? "1.5rem" : "0rem",
				opacity: isSelected ? 1 : 0,
			}}
			transition={{
				stiffness: 1000,
				damping: 30,
				type: "spring",
			}}
		>
			<IoScan />
		</motion.div>
	);
};
