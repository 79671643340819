import { db } from "@/core";
import type { ConstructionSite, ConstructionSiteContact } from "@msuite/katana";
import { DocumentString, HStack, Text, Tooltip, useUIContext } from "@msuite/picasso";
import type { FC } from "react";
import { TbCircleHalf2 } from "react-icons/tb";

const FALLBACK_LABEL = "Dieser Auftraggeber muss durch einen Kontakt ersetzt werden.";

/** Props Interface */
interface ConstructionSiteClientProps {
	constructionSite: ConstructionSite | undefined;
	/**
	 * Handler to fix legacy construction site client.
	 * @returns {void}
	 */
	onFix: (() => void) | undefined;
	shouldExtendSearch?: boolean;
}

export const ConstructionSiteClient: FC<ConstructionSiteClientProps> = ({
	constructionSite,
	onFix,
	shouldExtendSearch,
}) => {
	/** Hooks */
	const { colors } = useUIContext();

	/** Render */
	if (constructionSite?.clientId) {
		if (shouldExtendSearch) {
			return (
				<DocumentString<ConstructionSiteContact>
					path={`_contactBook/${constructionSite.clientId}`}
					shouldExtendSearch
					id={constructionSite.clientId}
					db={db}
					fields={["name"]}
				/>
			);
		}
		return (
			<DocumentString<ConstructionSiteContact>
				path={`_contactBook/${constructionSite.clientId}`}
				db={db}
				fields={["name"]}
			/>
		);
	}

	/** Fallback Render */
	return (
		<HStack
			spacing={1.5}
			alignItems="center"
		>
			{onFix && (
				<Tooltip label={FALLBACK_LABEL}>
					<Text
						color={colors.orange}
						cursor="pointer"
						onClick={onFix}
					>
						<TbCircleHalf2 />
					</Text>
				</Tooltip>
			)}
			<Tooltip label={constructionSite?.auftraggeber ?? FALLBACK_LABEL}>
				<Text noOfLines={1}>{constructionSite?.auftraggeber ?? FALLBACK_LABEL}</Text>
			</Tooltip>
		</HStack>
	);
};
