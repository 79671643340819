import { wait } from "@msuite/katana";
import { toast } from "@msuite/picasso";
import { useId, useLayoutEffect, useState, type FC } from "react";
import * as TrimbleConnectWorkspace from "trimble-connect-workspace-api";

const SPLIT_TOKEN_PROJECT_ID = "https://web.connect.trimble.com/projects/";
const SPLIT_TOKEN_SHARE_TOKEN = "&stoken=";

/** Props Interface */
interface TrimbleConnectIFrameProps {
	trimbleLink: string;
}

export const TrimbleConnectIFrame: FC<TrimbleConnectIFrameProps> = ({ trimbleLink }) => {
	/** State */
	const [trimbleIsLoaded, setTrimbleIsLoaded] = useState<boolean>(false);

	/** Hooks */
	const renderId = useId();
	const trimbleConnectIFrameId = `trimble-connect-iframe-${renderId}`;

	/** Functions */
	function getProjectId() {
		const splitted = trimbleLink.split(SPLIT_TOKEN_PROJECT_ID);
		const segments = splitted[1].split("/");
		const projectId = segments[0];
		return projectId;
	}

	function getShareToken() {
		const splitted = trimbleLink.split(SPLIT_TOKEN_SHARE_TOKEN);
		const shareToken = splitted.at(-1);
		return shareToken;
	}

	function setUISettings(API: TrimbleConnectWorkspace.WorkspaceAPI) {
		/** SIDE PANEL */
		API.ui.setUI({
			name: "SidePanel",
			state: "hidden",
		});

		/** TOOLBAR */
		API.ui.setUI({
			name: "MenuToolbar",
			state: "hidden",
		});

		API.ui.setUI({
			name: "MainToolbar",
			state: "hidden",
		});

		/** DETAILS PANEL */
		API.ui.setUI({
			name: "DetailsPanel",
			state: "hidden",
		});

		/** DETAILS PANEL VIEWS */
		API.ui.setUI({
			name: "DetailsPanel.Views",
			state: "hidden",
		});
	}

	async function onTrimbleConnect(tryCount = 0) {
		try {
			await wait(350);
			if (trimbleIsLoaded) return;
			if (tryCount > 10) {
				return;
			}
			const element = document.getElementById(trimbleConnectIFrameId) as HTMLIFrameElement;
			const API = await TrimbleConnectWorkspace.connect(element, () => {}, 60_000 * 5);
			const projectId = getProjectId();
			const shareToken = getShareToken();
			await API.embed.setTokens({ shareToken });
			await API.embed.init3DViewer({ projectId });
			setUISettings(API);
			setTrimbleIsLoaded(true);
		} catch (error) {
			console.log(error);
			if (tryCount >= 10) {
				toast.error("Trimble Connect konnte nicht geladen werden.");
			}
			setTimeout(() => onTrimbleConnect(tryCount + 1), 1);
		}
	}

	/** Effects */
	useLayoutEffect(() => {
		onTrimbleConnect();
	}, []);

	/** Render */
	return (
		<iframe
			src="https://web.connect.trimble.com/?isEmbedded=true"
			id={trimbleConnectIFrameId}
			sandbox="allow-scripts allow-modals allow-forms allow-same-origin"
			title="Trimble Connect"
			style={{
				width: "100%",
				height: "70vh",
				borderWidth: "1px",
				overflow: "hidden",
				borderRadius: "0.5rem",
			}}
		/>
	);
};
