import type { ConstructionSite, Ticket } from "@msuite/katana";
import { generateId } from "@msuite/katana";
import { Button, HStack, IconButton, Label, Spacer, VStack, toast } from "@msuite/picasso";
import { Collapser } from "components/collapser";
import { ConstructionSiteHeaderMin } from "components/construction-site";
import { type FC, useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { IoAdd } from "react-icons/io5";
import { TicketForm, type TicketFormType } from "./ticket-form";
import { TicketFormPlaceholder } from "./ticket-form-placeholder";

const ticketFormTypes: TicketFormType[] = ["active", "inactive"];
const labels: Record<TicketFormType, string> = {
	active: "Aktive Tickets",
	inactive: "Inaktive Tickets",
};

/** Props Interface */
interface TicketsFormProps {
	modalRef?: React.RefObject<HTMLDivElement>;
	initialFocusTicketId?: string;
	constructionSiteId: string | undefined;
}

const DEFAULT_MAX_VISIBLE = 10;

export const TicketsForm: FC<TicketsFormProps> = ({ modalRef, initialFocusTicketId, constructionSiteId }) => {
	/** State */
	const [isVisible, setIsVisible] = useState<[boolean, boolean]>([true, false]);
	const [maxVisible, setMaxVisible] = useState<[number, number]>([99, DEFAULT_MAX_VISIBLE]);

	/** Render */
	const methods = useFormContext<ConstructionSite>();
	const { control, watch } = methods;
	const active = useFieldArray({ control, name: "tickets" });
	const inactive = useFieldArray({ control, name: "inactive" });
	const array = { active: watch("tickets"), inactive: watch("inactive") };

	/** Effects */
	useEffect(() => {
		if (isVisible[0]) return;
		setMaxVisible((prev) => [DEFAULT_MAX_VISIBLE, prev[1]]);
	}, [isVisible[0]]);

	useEffect(() => {
		if (isVisible[1]) return;
		setMaxVisible((prev) => [prev[0], DEFAULT_MAX_VISIBLE]);
	}, [isVisible[1]]);

	/** Functions */
	function handleAppendNewTicket() {
		const id = generateId();
		active.append({ id, type: "Auf", ticket: "" });
	}

	function handleOnCollapse(index: number) {
		const newVisibleState = [...isVisible];
		newVisibleState[index] = !newVisibleState[index];
		setIsVisible(newVisibleState as [boolean, boolean]);
	}

	function handleOnDeleteTicket(formPrefix?: string) {
		if (!formPrefix) return;
		const value = methods.getValues(formPrefix as any) as Ticket;
		if (!value) return;
		const activeTickets = methods.getValues("tickets");
		if (value.ticket) {
			inactive.append(value);
		}
		const newActiveTickets = activeTickets?.filter((ticket: Ticket) => ticket.id !== value.id);
		methods.setValue("tickets", newActiveTickets);
	}

	function handleCopyTicket(formPrefix?: string) {
		if (!formPrefix) return;
		const currentTicket = { ...methods.getValues(formPrefix as any) } as Ticket;
		currentTicket.id = generateId();
		currentTicket.estimation = undefined;
		currentTicket.note = undefined;
		currentTicket.date = undefined;
		currentTicket.date_is_deadline = undefined;
		currentTicket.date_earliest_from = undefined;
		currentTicket.files = undefined;
		active.append(currentTicket);
		toast.success("Ticket kopiert");
	}

	/** Render */
	return (
		<VStack spacing={4}>
			{constructionSiteId && <ConstructionSiteHeaderMin constructionSiteId={constructionSiteId} />}
			{ticketFormTypes.map((ticketFormType, index) => (
				<VStack
					spacing={0}
					key={ticketFormType}
				>
					<HStack
						justifyContent="flex-start"
						spacing={2}
					>
						<Collapser
							isCollapsed={!isVisible[index]}
							onToggle={() => handleOnCollapse(index)}
						/>
						<Label
							cursor="pointer"
							onClick={() => handleOnCollapse(index)}
						>
							{labels[ticketFormType]} ({array[ticketFormType]?.length ?? 0})
						</Label>
						<Spacer />
						{ticketFormType === "active" && (
							<IconButton
								size="xs"
								onClick={handleAppendNewTicket}
								icon={<IoAdd />}
								aria-label="ticket hinzufügen"
							/>
						)}
					</HStack>
					{isVisible[index] && (
						<VStack
							spacing={4}
							pt={4}
							pb="1px"
						>
							{!array[ticketFormType]?.length && (
								<TicketFormPlaceholder onClick={ticketFormType === "active" ? handleAppendNewTicket : () => {}} />
							)}
							{[...(array[ticketFormType] ?? [])].slice(0, maxVisible[index]).map((ticket, index) => {
								const isReversed = ticketFormType === "inactive";
								const parsedIndex = isReversed ? (array[ticketFormType]?.length ?? 0) - index - 1 : index;

								return (
									<TicketForm
										withFirstLineLabels
										modalRef={modalRef}
										index={index}
										key={`${ticket.id}${parsedIndex}`}
										onDelete={ticketFormType === "active" ? handleOnDeleteTicket : undefined}
										onCopy={handleCopyTicket}
										isInactive={ticketFormType === "inactive"}
										formPrefix={ticketFormType === "active" ? `tickets.${parsedIndex}` : `inactive.${parsedIndex}`}
										constructionSiteId={constructionSiteId}
										methods={methods}
										initialFocusTicketId={initialFocusTicketId}
									/>
								);
							})}
							{(array[ticketFormType]?.length ?? 0) > maxVisible[index] && (
								<Button
									size="xs"
									onClick={() => setMaxVisible([maxVisible[0] + 10, maxVisible[1] + 10])}
									width="max-content"
									alignSelf="center"
									variant="ghost"
									colorScheme="gray"
								>
									Mehr anzeigen
								</Button>
							)}
						</VStack>
					)}
				</VStack>
			))}
		</VStack>
	);
};
