import { db } from "@/core";
import { type DailyColonPlan, type Employee, romanize } from "@msuite/katana";
import { DocumentString, HStack, Text } from "@msuite/picasso";
import type { FC } from "react";

/** Props Interface */
interface QuickAssignItemColonProps {
	colon: DailyColonPlan;
}

export const QuickAssignItemColon: FC<QuickAssignItemColonProps> = ({ colon }) => {
	/** Constants */
	const colonIsOwn = colon.name === "Kolonne";

	if (!colon) return null;

	/** Render */
	return (
		<HStack
			width="100%"
			justifyContent="space-between"
		>
			<Text fontSize="sm">
				{colon?.name} {romanize(colon?.rating)}
			</Text>
			<Text fontSize="sm">
				{colonIsOwn && typeof colon?.mitarbeiter?.at(0) === "string" ? (
					<DocumentString<Employee>
						db={db}
						path={`mitarbeiter/${colon?.mitarbeiter?.at(0) ?? "-"}`}
						subscribe={false}
						fields={["vorname", "nachname"]}
					/>
				) : (
					<Text>{colon?.mitarbeiter?.at(0) ?? "--"}</Text>
				)}
			</Text>
		</HStack>
	);
};
