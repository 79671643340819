import { useConstructionSiteCardContext } from "@/context/construction-site-card";
import { usePlanContext } from "@/context/plan";
import type { Ticket } from "@msuite/katana";
import { useUIContext } from "@msuite/picasso";

export const useTicketMetaInformation = (ticket: Ticket | undefined) => {
	/** Context */
	const { activeDailyColons, activeDailyInfo } = usePlanContext();
	const { hasCurrentAssignment, shouldFetchMetaInformation } = useConstructionSiteCardContext();
	const { colors } = useUIContext();

	/** Memoized */
	const ticketColor = (() => {
		if (!shouldFetchMetaInformation) return undefined;
		if (!ticket) return undefined;
		if (
			hasCurrentAssignment &&
			activeDailyColons.flatMap((colon) => colon.baustellen).some((c) => c?.ticket === ticket?.id)
		) {
			return colors.emerald;
		}
		if (activeDailyInfo?.na?.includes(ticket.id)) {
			return colors.yellow;
		}
		if (ticket.date) {
			return colors.blue;
		}
		return undefined;
	})();

	return { ticketColor };
};
