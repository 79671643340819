import { type AbwesenheitStatusTypes, absenceStatusToReadable } from "@msuite/katana";
import { Circle, HStack, Text, useUIContext } from "@msuite/picasso";
import { type FC, useMemo } from "react";

/** Props Interface */
interface StatusProps {
	status?: AbwesenheitStatusTypes;
	size?: "xs" | "sm";
}

export const Status: FC<StatusProps> = ({ status, size }) => {
	/** Context */
	const { colors } = useUIContext();
	const { red, blue, emerald, gray } = colors;

	/** Memoized */
	const color = useMemo(() => {
		if (status === "eingereicht") return gray;
		if (status === "gesehen") return blue;
		if (status === "abgelehnt") return red;
		if (status === "canceled") return red;
		if (status === "genehmigt") return emerald;
		return gray;
	}, [status, gray, blue, red, emerald]);

	/** Fallback */
	if (!status) return null;

	/** Render */
	return (
		<HStack
			fontSize={size}
			spacing={2}
		>
			<Circle
				size={size === "xs" ? "0.5rem" : "0.75em"}
				bg={color}
			/>
			<Text
				fontWeight="medium"
				color={color}
			>
				{absenceStatusToReadable(status)}
			</Text>
		</HStack>
	);
};

/** Default Props */
Status.defaultProps = {
	size: "xs",
};
