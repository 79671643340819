import { createContext, useContext } from "react";

/** Interface */
interface ILightboxContext {
	lightboxId?: string;
}

/** Context */
export const LightboxContext = createContext<ILightboxContext>({} as ILightboxContext);

/** Hooks */
export const useLightboxContext = () => {
	return useContext(LightboxContext);
};
