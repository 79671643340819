import { ConstructionSiteClient } from "@/components/construction-site";
import { useAppContext } from "@/context/app";
import { useConstructionSiteCardContext } from "@/context/construction-site-card";
import { Box, HStack, Text, Tooltip, VStack, WorkspaceBadge, useDisclosure } from "@msuite/picasso";
import type { FC } from "react";
import { Hint } from "./hint";
import { ManagerStack } from "./manager-stack";
import { MergeHint } from "./merge-hint";
import { QuickAddTicket, QuickAddTicketButton } from "./quick-add-ticket";

export const MainInformation: FC = () => {
	/** Context */
	const { activeWorkspace } = useAppContext();
	const {
		constructionSite,
		modules,
		isInView,
		userIsOwner,
		editConstructionSiteModal,
		alwaysShowWorkspaceBadge,
		isArchived,
	} = useConstructionSiteCardContext();

	/** Hooks */
	const { isOpen, onToggle, onClose } = useDisclosure();

	const addressString = `${constructionSite?.strasse} ${constructionSite?.hausnummer}, ${constructionSite?.plz} ${constructionSite?.ort}`;
	const hasAddTicket = modules?.includes("add-ticket");

	/** Render */
	return (
		<VStack
			spacing={0}
			pt={1}
		>
			<VStack
				spacing={1}
				width="100%"
			>
				<HStack
					width="100%"
					justifyContent="space-between"
					spacing={2}
				>
					{isArchived ? <MergeHint /> : null}
					<Box flex={1}>
						<Tooltip
							label={constructionSite?.bauvorhaben}
							openDelay={500}
						>
							<Text
								noOfLines={1}
								fontWeight="bold"
								cursor="pointer"
							>
								{constructionSite?.bauvorhaben}
							</Text>
						</Tooltip>
					</Box>
					<HStack
						spacing={4}
						justify="flex-end"
					>
						{constructionSite?.note && <Hint hint={constructionSite.note} />}
						{(alwaysShowWorkspaceBadge || constructionSite?.plangruppe !== activeWorkspace) && (
							<WorkspaceBadge workspaceId={constructionSite?.plangruppe} />
						)}
						<Text
							fontSize="sm"
							whiteSpace="nowrap"
						>
							{constructionSite?.nummer}
						</Text>
					</HStack>
				</HStack>
				<HStack
					justifyContent="space-between"
					fontSize="sm"
				>
					<Box maxW="66%">
						<Tooltip
							label={addressString}
							openDelay={500}
						>
							<Text
								noOfLines={1}
								whiteSpace="nowrap"
							>
								{addressString}
							</Text>
						</Tooltip>
					</Box>
					<ManagerStack />
				</HStack>
				<HStack
					justifyContent="space-between"
					fontSize="sm"
				>
					<ConstructionSiteClient
						constructionSite={constructionSite}
						onFix={editConstructionSiteModal.onOpen}
					/>
					{hasAddTicket && userIsOwner && <QuickAddTicketButton onClick={onToggle} />}
				</HStack>
			</VStack>
			{hasAddTicket && isInView && (
				<QuickAddTicket
					isOpen={isOpen}
					onClose={onClose}
				/>
			)}
		</VStack>
	);
};
