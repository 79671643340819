import { moment } from "@msuite/katana";
import { FormErrorMessage } from "@msuite/picasso";
import * as yup from "yup";
import { getDateFormatFromMode, inferDateMode } from "./date-picker/handlers";

export interface TicketDateForm {
	date: string;
	dateIsDeadline: boolean;
	withEarliestFrom: boolean;
	dateEarliestFrom: string;
}

const ticketDateFormShape: Record<keyof TicketDateForm, yup.SchemaOf<any>> = {
	date: yup.string().when("dateEarliestFrom", {
		is: (dateEarliestFrom: string) => !!dateEarliestFrom,
		then: yup.string().notRequired(),
		otherwise: yup.string().required("Bitte Datum angeben."),
	}),
	dateIsDeadline: yup
		.boolean()
		.when("date", {
			is: (date: string) => !date,
			then: yup.boolean().transform(() => true),
		})
		.default(false),
	withEarliestFrom: yup.boolean().default(false).required(),
	dateEarliestFrom: yup.string().when("withEarliestFrom", {
		is: true,
		then: yup
			.string()
			.test("is-valid-date", "Datum darf nicht vor gewähltem Datum liegen Zeitpunkt liegen", function () {
				const state = this.parent as TicketDateForm;
				const dateValue = state.date;
				const dateEarliestFromValue = state.dateEarliestFrom;
				const dateMode = inferDateMode(dateValue);
				const dateEarliestFromMode = inferDateMode(dateEarliestFromValue);
				const dateFormat = getDateFormatFromMode(dateMode);
				const dateEarliestFromFormat = getDateFormatFromMode(dateEarliestFromMode);
				const dateMoment = moment(dateValue, dateFormat);
				const dateEarliestFromMoment = moment(dateEarliestFromValue, dateEarliestFromFormat);
				if (dateMode === "week") dateMoment.startOf("week");
				if (dateEarliestFromMode === "week") dateEarliestFromMoment.startOf("week");
				if (dateEarliestFromMoment.isAfter(dateMoment)) return false;
				return true;
			})
			.required("Bitte Datum angeben."),
	}),
};

export const ticketDateFormSchema = yup.object().shape(ticketDateFormShape);
export const FormError = FormErrorMessage<TicketDateForm>;
