import { Image as ChakraImage } from "@msuite/picasso";
import type { FC } from "react";
import { FileLightBox } from "./file-light-box";

/** Props Interface */
interface FileImageProps {
	src: string | undefined;
	imageSize: { width: number; height: number } | null;
}

export const FileImage: FC<FileImageProps> = ({ src, imageSize }) => {
	const isLandscape = (imageSize?.width ?? 0) > (imageSize?.height ?? 0);

	/** Render */
	return (
		<FileLightBox>
			<ChakraImage
				src={src}
				height={isLandscape ? "100%" : undefined}
				width={isLandscape ? undefined : "100%"}
				loading="eager"
				objectFit="cover"
				cursor="pointer"
			/>
		</FileLightBox>
	);
};
