import { useFileGalleryModalContext } from "@/context/file-gallery-modal";
import type { useFileStorage } from "@msuite/picasso";
import {
	Button,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	ModalTitle,
} from "@msuite/picasso";
import { deleteObject } from "firebase/storage";
import { type FC, useState } from "react";

/** Props Interface */
interface DeleteFileModalProps {
	isOpen: boolean;
	onClose: () => void;
	fileRef: ReturnType<typeof useFileStorage>["fileRef"];
}

export const DeleteFileModal: FC<DeleteFileModalProps> = ({ isOpen, onClose, fileRef }) => {
	/** Context */
	const { onDeleteCallback } = useFileGalleryModalContext();

	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);

	/** Functions */
	async function handleOnDelete() {
		try {
			setIsLoading(true);
			const fileName = fileRef.name;
			await onDeleteCallback(fileName);
			await deleteObject(fileRef);
			onClose();
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}

	/** Render */
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalHeader>
					<ModalTitle>Datei löschen?</ModalTitle>
				</ModalHeader>
				<ModalBody>
					Möchtest du die Datei wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.
				</ModalBody>
				<ModalFooter>
					<ModalButtonGroup>
						<Button
							onClick={onClose}
							variant="ghost"
						>
							abbrechen
						</Button>
						<Button
							colorScheme="red"
							onClick={handleOnDelete}
							isLoading={isLoading}
						>
							Löschen
						</Button>
					</ModalButtonGroup>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
