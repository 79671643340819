import { Box, useColorModeValue } from "@msuite/picasso";
import type { FC } from "react";

/** Pops Interface */
interface ActionBarButtonProps {
	color: string;
	onClick?: () => void;
	isDisabled?: boolean;
}

export const ACTION_BAR_BUTTON_SIZE = "0.85rem";

export const ActionBarButton: FC<ActionBarButtonProps> = ({ color, onClick, isDisabled }) => {
	/** Constants */
	const backgroundColor = useColorModeValue("gray.300", "gray.600");

	/** Render */
	return (
		<Box
			position="relative"
			width={ACTION_BAR_BUTTON_SIZE}
			style={{ aspectRatio: "1" }}
			borderRadius={`calc(${ACTION_BAR_BUTTON_SIZE} / 2)`}
			backgroundColor={backgroundColor}
			opacity={isDisabled ? 0.5 : 1}
			cursor={isDisabled ? "default" : "pointer"}
			onClick={isDisabled ? undefined : onClick}
			_hover={
				isDisabled
					? undefined
					: {
							backgroundColor: color,
						}
			}
		/>
	);
};
