import { ax } from "@/core";
import type { DailyColonPlan, Dayinfo, Moment } from "@msuite/katana";
import { moment } from "@msuite/katana";
import { useAppContext } from "context/app";
import { useEffect, useRef, useState } from "react";
import { usePublishListener } from "./dailies/daily/use-publish-listener";

export type PlanMode = "normal" | "planning";

const defaultWeek = moment().isoWeek();
const defaultYear = moment().year();

export function getWeeklyReferenceString(activeWorkspace: string | null, currentMoment: Moment) {
	return `planung/${activeWorkspace}/wochen/${currentMoment.format("WYYYY")}`;
}

export const usePlan = () => {
	/** Context */
	const { activeWorkspace } = useAppContext();

	/** Refs */
	const planRef = useRef();

	/** State */
	const [activeDailyInfo, setActiveDailyInfo] = useState<Dayinfo | undefined>();
	const [activeDailyColons, setActiveDailyColons] = useState<DailyColonPlan[]>([]);
	const [copyIsProcessed, setCopyIsProcessed] = useState<boolean>(false);

	const [planMode, setPlanMode] = useState<PlanMode>("normal");
	const [week, setWeek] = useState<number>(defaultWeek);
	const [year, setYear] = useState<number>(defaultYear);
	const [activeDate, setActiveDate] = useState<Moment>(handleFindActiveDate());

	const isPlanModeActive = planMode === "planning";
	const currentMoment = moment().clone().isoWeek(week).year(year);
	const weeklyReference: string = getWeeklyReferenceString(activeWorkspace, currentMoment);

	/** Functions */
	function handleFindActiveDate() {
		const initialActiveDate = moment().add(1, "day");
		if (initialActiveDate.isWeekend()) moment().getNextPossibleWorkday();
		return initialActiveDate;
	}

	/** Hooks */
	const { isBlocked: activeIsBlocked } = usePublishListener(activeDate);

	/** Effects */
	useEffect(() => {
		if (!activeDate) return;
		const weeklyMoment = moment().isoWeek(week).year(year);
		if (!weeklyMoment.isSame(activeDate, "week")) {
			setWeek(weeklyMoment.isoWeek());
			setYear(weeklyMoment.year());
		}
	}, [activeDate, week, year]);

	async function handleCopyPreviousWeek() {
		try {
			await ax.patch(`/planung/${activeWorkspace}/week/${currentMoment.format("WYYYY")}/copy`, {
				week,
				year,
			});
		} catch (error) {
			console.error(error);
		}
	}

	/** Return */
	return {
		planMode,
		setPlanMode,
		week,
		setWeek,
		year,
		setYear,
		currentMoment,
		activeWorkspace,
		activeDate,
		setActiveDate,
		weeklyReference,
		activeDailyInfo,
		setActiveDailyInfo,
		activeDailyColons,
		setActiveDailyColons,
		handleCopyPreviousWeek,
		copyIsProcessed,
		setCopyIsProcessed,
		isPlanModeActive,
		handleFindActiveDate,
		planRef,
		activeIsBlocked,
	};
};
