import type { RentListAnswer, RentListItem } from "@msuite/katana";
import { createContext, useContext } from "react";

/** Interface */
interface HandleRentListModalContext {
	currentState: RentListAnswer | "all";
	setCurrentState: (state: RentListAnswer | "all") => void;
	rentList: RentListItem[];
	globalFilter: string;
	setGlobalFilter: (value: string) => void;
}

/** Context */
export const HandleRentListModalContext = createContext<HandleRentListModalContext>({
	currentState: "all",
	setCurrentState: () => {},
	rentList: [],
	globalFilter: "",
	setGlobalFilter: () => {},
});

/** Hook */
export function useHandleRentListModalContext() {
	return useContext(HandleRentListModalContext);
}
